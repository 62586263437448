<template>
  <div class="disk-detail">
    <transition name="fade" mode="out-in">
      <div v-if="isCurrentLoading" class="disk-detail__loader">
        <base-loader class="disk-detail__loader-item" />
      </div>
      <base-alert
        v-else-if="!isCurrentLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="disk-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <div v-else-if="tariff && tariff.status" class="disk-detail__inner">
        <div class="disk-detail__content">
          <layout-stack-title
            :title="tariff.name"
            :address="tariff.id"
            :created="tariff.created_at"
            :status="tariff.status"
            :description="tariff.description"
            :state="tariff.serverState"
            class="disk-detail__head"
            ><template #btn>
              <v-popover
                v-if="isMobile && diskid"
                placement="bottom"
                popover-class="disk-detail__popover"
              >
                <sidebar-button />
                <template #popover>
                  <disk-context-menu
                    :tariff="tariff"
                    type-action="disk"
                    :is-loading="isCurrentLoading"
                    class="disk-detail__context-menu"
                  />
                </template> </v-popover
            ></template>
          </layout-stack-title>
          <tabs :list="nav" class="medium-title">
            <template v-slot:item="{ item }">
              <router-link exact-active-class="active" :to="item.to">
                {{ item.title }}
              </router-link>
            </template>
          </tabs>
          <transition name="slide-fade">
            <router-view v-if="tariff" :tariff="tariff"></router-view>
          </transition>
        </div>

        <main-card v-if="!isMobile" class="disk-detail__aside">
          <disk-context-menu
            v-if="diskid"
            :tariff="tariff"
            type-action="disk"
            :is-loading="isCurrentLoading || isLoading"
          />
          <base-loader v-else />
        </main-card>
      </div>
    </transition>
  </div>
</template>

<script>
import tabs from '@/components/Tabs/Tabs.vue';
import LayoutStackTitle from '@/components/LayoutTitle/LayoutStackTitle.vue';
import SidebarButton from '@/components/Buttons/SidebarButton.vue';
import MainCard from '@/components/MainCard/MainCard';
import DiskContextMenu from '../pages/Main/components/DiskContextMenu';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../mixins/index';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
export default {
  name: 'Disk',
  components: {
    LayoutStackTitle,
    tabs,
    SidebarButton,
    MainCard,
    DiskContextMenu,
    BaseAlert,
  },
  mixins: [storeMixin, providerChange],
  props: {
    diskid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCurrentLoading: false,
      isServerError: false,
      navRaw: [
        {
          title: this.$t('nav.config'),
          to: { name: 'DiskView' },
        },
        {
          title: this.$t('nav.snapshot'),
          to: { name: 'DiskSnapshots' },
        },
        // {
        //   title: this.$t('nav.stat'),
        //   to: { name: 'ServerStat' },
        // },
      ],
      isMobile: true,
    };
  },
  computed: {
    tariff() {
      return this.$store.state.moduleStack.volumes.find(x => x.id === this.diskid);
    },
    nav() {
      return this.navRaw.filter(
        i => !i.access || (i.access && this[i.access.name] === i.access.value)
      );
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
    this.newFetch();
  },
  methods: {
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "nav": {
        "config": "Настройки",
        "snapshot": "Снапшоты",
        "stat": "Статистика"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.disk-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    max-width: 100%;
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.disk-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
